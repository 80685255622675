<template>
  <BaseWindow
    @closeWindow="closeWindow"
    width="1250"
    height="820"
    :show="true"
    :loading="loading"
    innerBackground="linear-gradient(0deg, rgba(152,25,35,1) 0%, rgba(95,16,23,1) 100%)"
    :titleText="$t('friendsRequestWindow.friendsOffer')"
  >
    <template>
      <div class="friend-request-container">
        <!-- <div class="text header">
          {{ $t("friendsRequestWindow.friendsOffer") }}
        </div> -->
        <div class="content">
          <div class="player-avatar">
            <Avatar size="250" :url="sender.avatar" />
          </div>
          <div class="text player-id">ID : {{ sender.id }}</div>
          <div class="text username">
            <span class="player-name">{{ sender.name }}</span
            ><span
              class="info-text"
              v-html="$t('friendsRequestWindow.becomeFriends')"
            ></span>
          </div>
        </div>
        <div class="footer">
          <div class="reject-button">
            <button
              class="reject-button ui-button ui-button-small-red"
              @click="rejectFriendRequest"
            >
              {{ $t("general.reject") }}
            </button>
          </div>
          <div class="accept-button">
            <button
              class="ui-button ui-button-small-green"
              @click="acceptFriendRequest"
            >
              {{ $t("general.accept") }}
            </button>
          </div>
        </div>
      </div>
    </template>
  </BaseWindow>
</template>

<script>
import BaseWindow from "./BaseWindow";
import Avatar from "@/components/Misc/Avatar";

export default {
  name: "FriendsRequestWindow",
  data() {
    return {
      loading: false,
      sender: {},
      requestId: 0,
    };
  },
  components: {
    BaseWindow,
    Avatar,
  },
  methods: {
    closeWindow() {
      this.$store.commit("modals/hide", "friendRequest");
      this.$store.commit("friends/clearReceivedFriendRequest");
    },
    async showFriendRequest() {
      let data = this.$store.state.friends.receivedFriendRequest;
      this.loading = true;
      this.requestId = data.requestId;
      this.sender = data.user;
      this.loading = false;
    },
    async acceptFriendRequest() {
      const respondFriendRequest = await this.$store.dispatch(
        "friends/respondFriendRequest",
        {
          requestId: this.$data.requestId,
          senderId: this.sender.id,
          accept: true,
        }
      );
      if (respondFriendRequest) {
        this.closeWindow();
        await this.$store.dispatch("friends/getFriends", 0);
      }
    },
    async rejectFriendRequest() {
      await this.$store.dispatch("friends/respondFriendRequest", {
        requestId: this.$data.requestId,
        accept: false,
      });
      this.closeWindow();
    },
  },
  mounted() {
    this.showFriendRequest();
  },
};
</script>

<style lang="scss" scoped>
.friend-request-container {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  z-index: 99;
  padding-top: 20px;
  text-shadow: 0 3px 5px #000000;
  .text {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-transform: uppercase;
    font-weight: bold;
    padding: 10px;
    color: rgb(255, 255, 255);

    &.header {
      font-size: 45px;
      height: 10%;
    }

    &.username {
      font-size: 45px;
      display: flex;
      flex-direction: column;
      text-align: center;
      span {
        margin: 10px 0;
      }
      .info-text {
        line-height: 60px;
      }
      .player-name {
        color: #f17e2c;
      }
    }

    &.player-id {
      font-size: 40px;
    }

    &.info {
      font-size: 40px;
      font-weight: normal;
      text-transform: lowercase;
      height: 60px;
    }
  }

  .content {
    width: 100%;
    height: 60%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
  }

  .footer {
    width: 40%;
    height: 15%;
    justify-content: space-between;
    align-items: center;
    display: flex;
    position: relative;
    bottom: 20px;
    button {
      text-transform: uppercase;
    }
  }
}
</style>
